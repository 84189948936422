export default async function (body: any, locale: string = '') {
  const config = useRuntimeConfig()

  const host = await useGetHost()

  let apiPath = '/api/sszrfblpon'

  const headers: any = {
    'x-app-domain': host,
    'x-app-locale': locale,
  }

  const token: any = useCookie('token')

  if (token.value) {
    apiPath = '/api/sfasejfcii'
    _set(headers, 'Accept', 'application/json')
    _set(headers, 'Authorization', `Bearer ${token.value}`)
  }

  const refCode: any = useCookie('refCode')

  if (refCode.value)
    _set(body, 'ref', refCode.value)

  const data: any = await $fetch(apiPath, {
    baseURL: config.public.apiBase,
    method: 'POST',
    headers,
    body,
  }).catch(() => {
    token.value = null
    return false
  })

  return data
}
